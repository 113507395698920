import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element, defineDirective } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User, VerifiedUser } from '../shared/models/user.model';
// import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { AppointmentAddModel,AppointmentBaseModel } from '../shared/models/appointment.model';
import { UserDocumentsAddModel } from '../shared/models/user-documents.model';
import { UserDocumentResponse, DocumentUrlResponse } from '../shared/models/response.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserDocumentsService {

  baseUrl:String;
  isAdminView: boolean = false;
  visibililtyDoc : boolean =  true;
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
  } 

  public userDocuments(id:string) {
    //const params = new HttpParams().set('id', id).set('AdminView', this.isAdminView); 
    // let query_str = "id="+id+"&companyCode=DCF01";//JSON.parse(localStorage.getItem('CurrentUserId'));
    
    const params = new HttpParams().set('id',id).set('companyCode', "DCF01").set('isVisibleDoc', "true"); 

    // return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/Upload?'+query_str, formData).pipe(
    return this.http.get<UserDocumentResponse>(this.baseUrl+'/FortressDocument',{params}).pipe(  // +'&AdminView='+false
      catchError(this.handleError)
      );
  }

  public userDocumentsReorder(userDocumentList, id: string) {
    return this.http.put(this.baseUrl+'/FortressDocument/DocumentReorder?='+ id ,userDocumentList).pipe(
      catchError(this.handleError)
      );
  }

  public handleError(error) {
    return throwError(error);
  }

  public uploadDocument(userDocumentAdd:UserDocumentsAddModel){    
    console.log(userDocumentAdd);
    return this.http.post<UserDocumentResponse>(this.baseUrl +'/FortressDocument',userDocumentAdd).pipe(
      catchError(this.handleError)
      );    
  }

  public ViewDocument(url:string){    
    // console.log(userDocumentAdd);
    const params = new HttpParams().set('url', url); 
    return this.http.post<Response>(this.baseUrl +'/FileUpload/ViewDocument?url='+url,{params}).pipe(
      catchError(this.handleError)
      );    
  }
  public deleteUserDocument(document_id:string){
    const params = new HttpParams().set('id', document_id); 
    return this.http.delete<Response>(this.baseUrl +'/FortressDocument?id='+document_id,{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public getDocumentUrl(userDocument){
    var userId:string = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));

    const params = new HttpParams().set('S3Key', userDocument.documentURL).set('fileCategory', userDocument.documentType).set('userId', userId); 
    return this.http.get<DocumentUrlResponse>(this.baseUrl+'/FileUpload/GetDocumentUrl',{params}).pipe(
      catchError(this.handleError)
      );  

  }

  public downloadDocument(fdsId, envilopid){
    const params = new HttpParams().set('fdsId', fdsId).set('envilopid', envilopid); 
    return this.http.get(this.baseUrl+'/FDSServiceAgreement/getEnvelopDocument',{params}).pipe(
      catchError(this.handleError)
      );
  }

}
